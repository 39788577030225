import { FormikHelpers } from 'formik';
import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Display components
import AddMemberToPlanForm from 'display-components/add-family-member/add-member-to-plan-form';
import {
    MembershipAddMemberAlreadyExistErrorModalContent,
    MembershipSettingsErrorModal,
    MembershipSettingsSuccessModalContent
} from 'display-components/membership/modals';

// Components
import { AddressVerificationAPIFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { AddressVerificationForm } from 'components/address-verification-modal/AddressVerificationModal.component';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// State
import {
    accountDefaultPhoneSelector,
    accountProfileMembershipSelector,
    accountProfilEPostPatientNumSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetRacesRoutine
} from 'state/membership-registration/membership-registration.routines';
import {
    membershipRegistrationEthnicitiesListSelector,
    membershipRegistrationGendersListSelector,
    membershipRegistrationRacesListSelector
} from 'state/membership-registration/membership-registration.selectors';
import { extractIdFromGenderField } from 'state/membership/membership.helpers';
import { membershipActions } from 'state/membership/membership.reducers';
import { membershipAddMemberRoutine } from 'state/membership/membership.routines';
import { AddressParts } from 'state/usps/usps.reducers';

// Types
import {
    MembershipAddedResponse,
    MembershipAddMemberResponseData,
    MembershipPersonalDetailsFormValues
} from 'types/membership';

// Utils
import { PATIENT_ALREADY_INVITED } from 'util/membership';
import { convertToTitleCase } from 'util/string';
import { AddressValidateResponse } from 'util/usps';

// Hooks
import { useAddressVerification } from 'hooks/useAddressVerification';

const PersonalDetailsMinorPages = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.AddMinorDataQuery;
}): ReactElement | null => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyAddress } = useAddressVerification();

    const gendersList = useSelector(membershipRegistrationGendersListSelector);
    const racesList = useSelector(membershipRegistrationRacesListSelector);
    const ethnicitiesList = useSelector(membershipRegistrationEthnicitiesListSelector);

    /**
     * DRX-2397: As this values are used on Add Member flow, is
     * mandatory to have those values loaded before the user get
     * into the add member flow.
     */
    useEffect(() => {
        if ([gendersList, racesList, ethnicitiesList].some((list) => list.length === 0)) {
            dispatch(membershipRegistrationGetGendersRoutine.trigger());
            dispatch(membershipRegistrationGetRacesRoutine.trigger());
            dispatch(membershipRegistrationGetEthnicitiesRoutine.trigger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleOnCancel = () => {
        navigate('/secure/profile/membership');
    };

    const { membershipID } = useSelector(accountProfileMembershipSelector);
    const ownerEpostPatientNum = useSelector(accountProfilEPostPatientNumSelector);
    const ownerPhoneNum = useSelector(accountDefaultPhoneSelector);

    const successRedirectUrl = '/secure/profile/membership';

    // Show success modal after finish adding the dependent Minor < 13.
    const showNewMemberMinorSuccessModal = useCallback(
        (memberName: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    className: 'modal-membership-add-member',
                    bodyContent: (
                        <BirdiModalContent
                            icon={'default'}
                            title={t('pages.profile.membership.manageGroupSection.modalSuccessAddMember.heading')}
                            body={
                                <MembershipSettingsSuccessModalContent
                                    t={t}
                                    variation="addMember"
                                    memberName={convertToTitleCase(memberName)}
                                    onClose={() => {
                                        setIsBusy(false);
                                        dispatch(closeModal({}));
                                        navigate(successRedirectUrl);
                                    }}
                                />
                            }
                        />
                    )
                })
            );
        },
        [dispatch, t]
    );

    const showGenericErrorModal = useCallback(
        (memberToAdd: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <MembershipSettingsErrorModal
                            variation="addNewMember"
                            memberName={convertToTitleCase(memberToAdd)}
                            t={t}
                            onClose={() => dispatch(closeModal({}))}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const showMemberAlreadyAddedErrorModal = useCallback(
        (name: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.AddMemberErrorTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <MembershipAddMemberAlreadyExistErrorModalContent
                            name={name}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const verifyMemberAddress = (values: MembershipPersonalDetailsFormValues) => {
        const address: AddressParts = {
            city: values.city as string,
            state: values.state as string,
            street1: values.address1 as string,
            street2: values.address2 as string,
            zip: values.zipcode as string
        };

        setIsBusy(true);
        verifyAddress({
            address,
            onSuccess: (validationResponse: AddressValidateResponse) => {
                setIsBusy(false);

                if (validationResponse && validationResponse.responseCode === 'suggested') {
                    dispatch(
                        openModal({
                            showClose: false,
                            bodyContent: (
                                <AddressVerificationForm
                                    originalAddress={validationResponse.currentAddress!}
                                    correctedAddress={validationResponse.updatedAddress!}
                                    onSuccess={onVerificationAddressChoice(values)}
                                    onClose={onCloseValidationChoice}
                                    className="membership-form"
                                />
                            )
                        })
                    );
                }
            },
            onFailure: (validationResponse: AddressValidateResponse) => {
                const errors: { [key: string]: any } = {};
                setIsBusy(false);
                if (validationResponse.responseMessage) {
                    const errosField = validationResponse.responseField
                        ? validationResponse.responseField?.toLocaleLowerCase()
                        : 'address1';

                    errors[errosField] = validationResponse.responseMessage;
                }
                if (validationResponse && validationResponse.responseCode === 'invalid') {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <AddressVerificationAPIFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'AddressVerificationError'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    const onVerificationAddressChoice = (values: MembershipPersonalDetailsFormValues) => (address: AddressParts) => {
        if (address) {
            const valuesWithChosenAddress = {
                ...values,
                ...address
            };
            onCloseValidationChoice();
            handleOnSubmit(valuesWithChosenAddress);
        }
    };

    const onCloseValidationChoice = () => {
        dispatch(closeModal({}));
    };

    const handleOnSubmit = useCallback(
        (
            values: Partial<MembershipPersonalDetailsFormValues>,
            helpers?: FormikHelpers<Partial<MembershipPersonalDetailsFormValues>>
        ) => {
            setIsBusy(true);

            const parsedGenderValue = extractIdFromGenderField(values?.gender || '');

            const commonProps = {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim(),
                dateOfBirth: values.dateOfBirth,
                genderId: parsedGenderValue,
                ethnicityId: values.ethnicity,
                raceId: values.race,
                age: values.age,
                address: {
                    address: values.address1,
                    aptUnit: values.address2,
                    city: values.city,
                    state: values.state,
                    zipcode: values.zipcode
                },
                membershipId: membershipID || '',
                ePostPatientNumber: '',
                holderEPostPatientNumber: ownerEpostPatientNum || ''
            };

            let addMemberPayload;

            if (values.age && values.age <= 12) {
                addMemberPayload = {
                    ...commonProps,
                    mobilePhone: ownerPhoneNum?.uiPhone || ''
                };
            } else if (values.age && values.age >= 13 && values.age <= 17) {
                addMemberPayload = {
                    ...commonProps,
                    mobilePhone: values.phoneNumber || '',
                    emailAddress: values.email || ''
                };
            }

            if (addMemberPayload) {
                dispatch(membershipActions.isNewMember());
                if ((values.age as number) <= 12) {
                    dispatch(
                        membershipAddMemberRoutine.trigger({
                            data: addMemberPayload,
                            onSuccess: (response: MembershipAddedResponse) => {
                                setIsBusy(false);
                                showNewMemberMinorSuccessModal(response.firstName);
                                return;
                            },
                            onFailure: (errors: MembershipAddedResponse) => {
                                setIsBusy(false);
                                if (errors.messageText.includes(PATIENT_ALREADY_INVITED)) {
                                    showMemberAlreadyAddedErrorModal(values.firstName?.trim() as string);
                                } else {
                                    showGenericErrorModal(errors.firstName?.trim() as string);
                                }
                            }
                        })
                    );
                } else if ((values.age as number) >= 13 && (values.age as number) < 18) {
                    dispatch(membershipActions.setMemberToInvite(addMemberPayload as MembershipAddMemberResponseData));
                    navigate('/secure/profile/membership/send-invite');
                    return;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, membershipID, ownerEpostPatientNum, ownerPhoneNum]
    );

    if (!ENABLE_MEMBERSHIP) {
        navigate('/secure/profile/family-account');
        return null;
    }

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('Add member to your plan') }}
            anonymousOnly={false}
        >
            <WorkflowLayoutFormWrapper eyebrowText={t('Add member to your plan')} title={t('Their personal details.')}>
                <AddMemberToPlanForm
                    state={location.state}
                    isBusy={isBusy}
                    onCancel={handleOnCancel}
                    onSubmit={verifyMemberAddress}
                />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};
export default PersonalDetailsMinorPages;

export const query = graphql`
    query AddMinorData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
